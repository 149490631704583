import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Bottom of Page 2',
    nickname: 'bottomOfPage2',
  });

  widgetBuilder.behavior().set({
    removable: false,
  });
};
